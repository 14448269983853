@media only screen and (max-width: 1024px) {

	.wrapper, .wrapper-inside {
		width: 98%;
		padding: 0;
		margin: 0 auto;
	}

	#mainholder {
		width: 98%;
		padding: 10px 1% 1%;
		margin: 0 auto;
		box-sizing: border-box;
	}

	.Content1Column {
		width: 90%;
		padding: 5%;
	}

	//Slider

	#slider {
		width: 100%;
		margin: 0 auto;
	}

	.left-box-home {
		width: 66%
	}

	.Content2ColumnHome {
		width: 90%;
		padding: 5%;
	}

	.Content2Column {
		width: 56%;
		padding: 5%;
	}

	.ColumnRight {
		width: 30%
	}

	ul.nav li a {
		font-size: 1em;
	}

	ul.home-boxes {
		width: 100%;
		display: inline-block;
		margin: 10px 0;
	}

	ul.home-boxes li {
		width: 25%;
	}

	#awards {
		display: none;
	}
}

@media only screen and (max-width: 990px) {

	// Body

	body {
		width: auto;
		min-width: 300px;
		opacity: 1;
	}

	.logo {
		text-align: center;
	}

	.logo span {
		float: none;
		margin: 0 auto 5px;
		font-size: 25px;
		max-width: 95%;
	}

	.logo span span {
		float: none;
		margin: 0 auto;
		font-size: 16px;
		max-width: 75%;
	}

	// Global

	img {
		max-width: 100%;
		height: auto;
	}

	table {
		max-width: 100%;
	}

	// Wrappers
	.wrapper {
		width: 100%;
	}

	// Calendar
	#calendar {
		.fc-header td {
			display: block;
			width: 100%;
		}

		h2 {}

		.fc-button-content {
			font-family: Arial;
		}

		.fc-header-right {
			text-align: left;
		}

		.fc-header-center {
			text-align: left;
		}
	}

	// Forms

	form div input[type="text"],
	form div textarea,
	form span textarea,
	form div input[type="textarea"],
	form div input[type="password"] {
		width: 200px;
	}

	form div span.button {
		padding-left: 0;
	}

	#FS {
		display: none;
	}

	// Mainholder
	#mainholder {
		width: 100%;
		padding: 0;
		border-bottom: 2px solid #fff;
	}

	// Logo bar
	img#logo {
		margin: 60px auto 20px;
		max-width: 85%;
		display: block;
		float: none;
	}

	// Contents

	.Content1Column,
	.Content2Column,
	.Content3Column {
		width: 84%;
		padding: 5%;
		margin: 3%;
	}

	.content {
		width: 94%;
		padding: 0;
		margin: 3%;
	}

	// Col Left and Right
	.ColumnLeft,
	.ColumnRight {
		width: 94%;
		padding: 0;
		margin: 3%;
	}

	// Header
	header {
		height: auto;
		overflow: hidden;

		.wrapper {
			padding: 0;
		}
	}

	// Slider
	#slider {
		width: 100%;
		height: 250px;
		overflow: hidden;
		border: none;
	}

	//Home box
	ul.home-boxes {
		display: block;
		margin: 0 auto;

		li {
			width: 100%;
		}
	}

	//Content
	.Content1Column h1,
	.Content2Column h1 {
		margin: -6% -6% 20px -6%;
		padding: 5%;
	}

	//Footer
	footer {
		height: auto;

		.wrapper {
			width: 90%;
			padding: 0 5%;

			p {
				text-align: center !important;
				margin: 5px auto !important;
				float: none !important;
				font-size: 14px;
			}

		}
	}
}
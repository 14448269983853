// -----------------------------------//
// GLOBAL STYLES
// -----------------------------------//

// BODY

body {
	color: #222;
	font-size: 18px;
	font-family: $body-font;
	text-align: center;
	line-height: 1.528571429;
	padding: 0px;
	margin: 0;
	background: $body-bg;
	font-weight: 400;
}

// LINKS

body a {
	color: $main-links-colour;
	text-decoration: none;
	transition: (all 0.3s ease-in-out);
}

body a:hover,
body a:focus {
	text-decoration: underline;
	transition: (all 0.3s ease-in-out);
}

// LINKED IMAGES

a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

a img:hover {
	opacity: 0.8
}

// HEADINGS

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

h1,
h2 {
	font-family: $headings-font
}

h1 {
	font-size: 28px;
	margin: -5px 0 20px 0;
	line-height: 1.2;
}

h2 {
	font-size: 20px;
	margin: 25px 0 20px 0;
	line-height: 1.3;
}

h3 {
	font-size: 15px;
	margin: 25px 0 5px 0;
	padding: 5px 10px 5px 20px;
	background-color: $main-box-colour;
	color: #fff;
	font-weight: bold;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

// PARAGRAPHS AND LIST ITEMS
p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background-image: url(/i/icons/bullet_black.png);
	background-repeat: no-repeat;
}

// FORMS
form {
	margin: 0;
	padding: 0;
	@extend .clearfix;
}

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	border: 0;
	padding: 0;
	white-space: normal;
	*margin-left: -7px;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
	*overflow: visible;
}

button[disabled],
html input[disabled] {
	cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
	*height: 13px;
	*width: 13px;
}

input[type="search"] {
	-webkit-appearance: textfield;
	box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

textarea {
	resize: vertical;
	overflow: auto;
	vertical-align: top;
}

label {
	float: left;
	margin: 10px 0;
	width: 90px;
	display: inline;
}

form .formfield {
	margin: 10px;
	float: left;
	display: inline;
	width: 200px;
	border: 1px solid #CCC;
	padding: 2px;
}

form div {
	margin: 15px 0;
	display: block;
	clear: left;
	vertical-align: top;
}

form div input[type="text"],
form div textarea,
form span textarea,
form div input[type="textarea"],
form div input[type="password"] {
	border: 1px solid #CCC;
	padding: 4px;
	width: 300px;
	margin: 0;
	background-color: #EFEFEF;
}

input[type="text"]:focus,
form div textarea:focus,
form span textarea:focus,
form div input[type="textarea"]:focus,
form div input[type="password"]:focus {
	border-color: #000;
}

form div span.label label,
form div span.label,
span.spacer,
span.multiple span {
	width: 180px;
	float: left;
	margin: 0 0 0 0;
}

form div span.multiple {
	float: left;
}

form div span.button {
	padding-left: 180px;
	background: transparent;
	border: 0;
}

form div span.button input {
	background: $main-box-colour;
	color: #FFF;
	margin: 0;
	padding: 8px 10px;
	font-weight: bold;
	border: 0;
}

form div span.button input:hover {
	background: #888;
}

form div.clear {
	clear: both;
	padding-top: 5px;
}

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
}

.wrapper {
	width: 990px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	background: #fff;
	box-shadow: inset 0 5px 0 0 $box-3;
	height: 175px;
}

header>.wrapper {
	padding: 10px 0;
	position: relative;
	z-index: 60;
}

// -----------------------------------//
// LOGO AND STRAPLINE
// -----------------------------------//

img#logo {
	width: auto;
	float: left;
	margin: 10px 30px 0 0;
}

.logo {
	text-align: left;

	a {
		text-decoration: none !important;
	}

	span {
		float: left;
		color: $nav-bg;
		padding: 30px 0 0 0;
		font-size: 40px;
		font-weight: 700;

		span {
			float: left;
			display: block;
			padding: 5px 0 0 5px;
			font-size: 18px;
			color: $nav-bg;
		}
	}
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

#FS {
	position: absolute;
	right: 0;
	top: 110px;
}

#FS #search-field {
	width: 163px;
	height: 24px;
	box-shadow: inset 0 0 0 1px $search-bg;
	background: #fff;
	font-size: 12px;
	color: $search-bg;
	vertical-align: top;
	padding: 0 30px 0 10px;
	transition: 1s;
}

#FS #search-submit {
	border: 0;
	padding: 6px;
	margin: 0;
	cursor: pointer;
	background-color: $search-bg;
	position: absolute;
	top: 0;
	right: 0;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

#mainholder {
	width: 990px;
	margin: 0 auto 10px;
	padding: 5px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
}

.wrapper-inside {
	width: 990px;
	margin: 40px auto;
	height: 100%;
	@extend .clearfix;
}

.body_2804 {
	.wrapper-inside {
		margin: 0;
	}
}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1

.Content1Column {
	width: 930px;
	margin: 0;
	padding: 30px;
	float: left;
	min-height: 250px;
	text-align: left;
	background: rgba(255, 255, 255, .6);
	box-shadow: inset 0 0 0 1px #fff;

	h1 {
		background: $main-box-colour;
		color: #fff;
		text-transform: uppercase;
		font-size: 18px;
		font-weight: bold;
		margin: -24px -24px 30px -24px;
		padding: 13px 0 10px 24px;
		letter-spacing: 0.07em;
	}

	h2 {
		text-transform: uppercase;
		font-weight: bold;
	}
}

//Content 2

.Content2Column {
	width: 640px;
	margin: 0;
	padding: 30px;
	float: left;
	min-height: 250px;
	text-align: left;
	background: rgba(255, 255, 255, .6);
	box-shadow: inset 0 0 0 1px #fff;

	h1 {
		background: $main-box-colour;
		color: #fff;
		text-transform: uppercase;
		font-size: 18px;
		font-weight: bold;
		margin: -29px -24px 30px -24px;
		padding: 13px 0 10px 24px;
		letter-spacing: 0.07em;
	}

	h2 {
		text-transform: uppercase;
		font-weight: bold;
	}
}

//Content 3

.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 250px;
	text-align: left;
}

//Home boxes

ul.home-boxes {
	display: inline-block;
	margin: 10px 0 -18px 0;

	li {
		width: 247px;
		margin: 0;
		padding: 0;
		background: none;
		float: left;

		a {
			width: 100%;
			height: 120px;
			display: inline-block;
			text-align: center;
			color: #fff;

			span.title {
				display: block;
				font-size: 15px;
				font-weight: bold;
				text-transform: uppercase;
				margin: 30px 0 5px;


			}

			span.sub-text {
				display: block;
				font-size: 15px;
				font-weight: lighter;
			}

			img {
				display: inline-block;
				margin: 12px 0 0 0;
			}

			&:hover {
				box-shadow: inset 0 -54px 0 0 rgba(0, 0, 0, .1);
				;
				text-decoration: none;
			}
		}

		// -----------------------------------//
		// BOXES
		// -----------------------------------//

		a.box-1 {
			background: $box-1;
		}

		a.box-2 {
			background: $box-2;
		}

		a.box-3 {
			background: $box-3;
		}

		a.box-4 {
			background: $box-4;
		}

	}
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 270px;
	margin: 0;
	padding: 0 0 0 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 270px;
	margin: 0;
	padding: 0 0 0 0;
	min-height: 250px;
	text-align: left;
	float: right;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// Diary / News

#SideHeadingDiary,
#SideHeadingNews {

	ul.SideList {
		padding-bottom: 13px;

		a {
			color: $box-2;

			&:hover {
				span {
					text-decoration: underline;
				}
			}
		}
	}
}

//Related Pages

#SideHeadingPages {
	padding-bottom: 23px;

	h2 {
		margin: 0 0 10px 0;
	}

	ul.SideList {
		padding: 10px 0;

		a {
			padding: 7px 20px;
			font-size: 18px;
			color: #222;
			font-weight: bold;

			&:hover {
				text-decoration: none;
				color: #fff;
				background: $box-1;
			}
		}
	}
}

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 20px 0;
	padding: 0;
	background: rgba(255, 255, 255, .6);
	box-shadow: inset 0 0 0 1px #fff;
}

.SideHeading:first-child {
	margin-top: 0;
}

// Heading
.SideHeading h2 {
	background: $main-box-colour;
	color: #fff;
	text-transform: uppercase;
	font-size: 20px;
	font-weight: bold;
	margin: 0 0 9px 0;
	padding: 12px 0 10px 24px;
	letter-spacing: 0.07em;
}

// List
ul.SideList {
	margin: 0 0 0px 0;
	padding: 0;
	border: 0;
	list-style: none;
}

ul.SideList li {
	margin: 0;
	padding: 0;
	background: none;
	border-bottom: 0;
}

ul.SideList li a {
	margin: 0;
	padding: 9px 30px;
	color: #333;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	color: #000000;
}

ul.SideList li a.child {
	padding-left: 47px !important;
	position: relative;

	&:before {
		content: "";
		width: 4px;
		height: 4px;
		border-radius: 10px;
		background: #535353;
		position: absolute;
		left: 31px;
		top: 14px;
	}
}

// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	width: 20px;
	color: $main-box-colour;
	font-weight: bold;
	text-decoration: none;
	font-size: 15px;
	line-height: 120%;
}

ul.SideList p.title {
	float: right;
	width: 77%;
	min-height: 40px;
	display: table;
	line-height: 130%;

	span {
		display: table-cell;
		vertical-align: middle;
	}
}

// More links
.box-more {
	display: block;
	background: $main-box-colour;
	text-align: center;
	position: relative;

	a.more {
		width: auto;
		padding: 10px 20px;
		display: inline-block;
		color: #fff;
		font-weight: bold;

		&:hover {
			text-decoration: none;
			opacity: .6;
		}
	}
}

// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	height: 80px;
	overflow: hidden;
	background: #fff;

	.CMSawards {
		overflow: hidden;
		width: 990px;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;

			li {
				background: none;
				padding: 0 5px;
				margin: 0;
				display: inline-block;

				img {
					max-height: 70px;
					transform: none !important;
				}
			}
		}
	}
}

// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	clear: both;
	background: $footer-bg;
	padding: 30px 0 20px;
	box-sizing: border-box;
	overflow: hidden;

	// this stops the gap below the footer and maintains 100vh
	box-shadow: 0 10px 0 0 $footer-bg;

}

footer>.wrapper {
	text-align: left;
}

footer>.wrapper p {
	margin: 0 0 5px 0;
	color: #fff;
	float: left;
	font-size: 16px;
}

footer>.wrapper a {
	color: $footer-links;
}

footer #credit {
	margin: 47px 0 0 0;
	padding: 0;
	float: right;
	text-align: right;
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: #FFF;
$table-highlight-background-color: #666;
$table-highlight-font-weight: bold;
$table-border-color: #EFEFEF;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	font-family: $body-font;
}

$blockquote-background-color: $box-2;
$blockquote-border-color: $box-2;
$blockquote-color: #fff;
$blockquote-speech-mark-color: #fff;
$blockquote-font-size: 1em;

// -----------------------------------//
// CALENDAR TABLE BACKGROUND
// -----------------------------------//

.fc-content {
	background: none;
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element {}

// -----------------------------------//
// BROWSER DETECTION
// -----------------------------------//

#browserblast {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 40px;
	border-bottom: 1px solid #D4C790;
	font-family: sans-serif;
	font-size: 12px;
	font-weight: bold;
	line-height: 40px;
	color: #6d6647;
	text-align: center;
	background: #FAF4AF;
}

#browserblast a {
	color: green;
	text-decoration: underline;
}

.unsupported-browser {
	margin-top: 41px;
}

// -----------------------------------//
// SWITCH MOBILE
// -----------------------------------//

#switch-mobile {
	width: 100%;
	margin: 0;
	padding: 20px 0;
	background: #EFEFEF;
	border-top: 1px solid #EBEBEB;
	border-bottom: 1px solid #EBEBEB;
	text-align: center;
	color: #777;
	font-family: sans-serif;
	font-size: 12px;
	display: block;
	clear: both;
}

#switch-mobile a {
	text-decoration: underline;
}
// -----------------------------------//

///////////// --- DOWNLOAD TO /INC/ ... FROM CMS_DEFAULT: --- //

// TSTYLES, THEADER, TFOOTER, SUBNEWSLATEST, SUBDIARYLATEST, JAVASCRIPT + JS FOLDER

//////////// --- ON INTEGRATION, REPLACE --- //

// --------- School Name Here --------- //

//////////// --- WITH --- //

// <%=Session("SchoolName")%> //

// Fonts

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

$body-font: 'Montserrat',
sans-serif;
$headings-font: 'Montserrat',
sans-serif;

// ------------------------------------------------------ //
// FIND / REPLACE #C0101B, #111, #222, #333, #444, #555, #666
// ------------------------------------------------------ //

// Main elements
$body-bg: #fff;
$main-links-colour: #0d770a;

// Header
$nav-bg: #C0101B;
$nav-hover: #fff;
$nav-submenu-hover: #8f0d16;
$search-bg: #C0101B;

// General Elemnts
$main-box-colour: #C0101B;

// Home boxes
$box-1: #1DBE3A;
$box-2: #21d742;
$box-3: #1DBE3A;
$box-4: #21d742;

// News
$news-bg: #f6fae7;
$news-border: #C0101B;
$news-hover: #C0101B;

// Diary
$diary-bg: #C0101B;
$diary-border: #555;

// Related page
$related-pages-border:#C0101B;
$related-pages-bg: #555;

// Footer
$footer-links: #fff;
$footer-bg: #C0101B;

// -----------------------------------//
// ELEMENTS COLOURS
// -----------------------------------//

// Grid and List view styles
$list-grid-tooltip-bg: #C0101B;

// News list inside page colours
$little-date-line: #C0101B;
$news-list-bg: #efefef;

// Subscribe
$subscribe-link-colour: #fff;
$subscribe-bg-colour: #C0101B;

// Diary list inside page colours
$diary-date-bg: #C0101B;
$diary-list-bg: #efefef;
$diary-even-row-bg: #ececec;
$add-to-calendar-bg: #C0101B;

// Auto List
$auto-list-bg: #f7f7f7;
$auto-list-border-colour: #dedede;

// Gallery
$gallery-bg: #C0101B;

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: #C0101B;
$search-highlight-color: #fff;